<template>
  <section id="surgery-products-usage">
    <div class="breadcrumbs mb-3 ml-3">
      <router-link to="/centro-cirurgico/lista-pacientes" tag="span" class="link">
        Centro cirúrgico
      </router-link>
      <ChevronRight class="icon"/>

      <router-link :to="`/centro-cirurgico/dashboard/${surgeryId}`" tag="span" class="link" v-if="!loading">
        {{ surgery?.appointment?.patient?.name }}
      </router-link>
      <b-skeleton v-else type="text" width="200px" class="mb-0" />

      <ChevronRight class="icon"/>
      <span>Gerenciar Mat/med</span>
    </div>

    <Skeleton v-if="loading" />
    <div v-else class="pb-5">
      <SurgeryProductsTemplates 
        :surgery="surgery" 
        :loading="loadingItems"
      />
      <UsedSurgeryItems 
        :surgery="surgery" 
        :items="surgeryItems.filter(el => el?.situation === 'use')" 
        @update="getSurgeryItems" 
        @onTransaction="reloadSurgeryItems"
        @remove="removeSurgeryItem"
        @moveToWarehouse="el => updateSurgeryItemUsage(el, 'return')"
        :loading="loadingItems"
      />  
      <StockSurgeryItems 
        :surgeryId="surgeryId" 
        :items="surgeryItems.filter(el => el?.situation === 'return')" 
        @update="getSurgeryItems" 
        @onTransaction="reloadSurgeryItems"
        @remove="removeSurgeryItem"
        @moveToUsed="$id => updateSurgeryItemUsage($id, 'use')"
        :loading="loadingItems"
      />
    </div>

  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager.js'

export default {
  name: 'ProductsContainer',

  components: {
    Skeleton: () => import('./ProductUsageSkeleton.vue'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    SurgeryProductsTemplates: () => import('@/components/SurgeryCenter/Products/SurgeryProductsTemplates.vue'),
    UsedSurgeryItems: () => import('@/components/SurgeryCenter/Products/UsedSurgeryItems.vue'),
    StockSurgeryItems: () => import('@/components/SurgeryCenter/Products/StockSurgeryItems.vue'),
  },  

  data () {
    return {
      clinic: getCurrentClinic(),
      surgeryId: this.$route.params.surgeryId,
      surgery: {},
      surgeryItems: {},

      loading: true,
      loadingItems: true,

      kits: [],
      procedures: [],
    }
  },

  async created() {
    this.loading = true
    await this.getSurgeryInformation()
    await this.getSurgeryProceduresProducts()
    await this.getSurgeryItems()
    this.loading = false
  },

  methods: {
    async getSurgeryInformation () {
      try {
        const res = await this.api.getSurgery(this.surgeryId)
        this.surgery = res.data
      } catch (error) {
        this.$toast.error('Erro ao carregar a cirurgia, contate o suporte se o erro persistir')
      }
    },
    async reloadSurgeryItems () {
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.getSurgeryItems(this.surgeryId)
        this.surgeryItems = res.data
      } catch (error) {
        this.$toast.error('Erro ao carregar os itens da cirurgia, contate o suporte se o erro persistir')
      } finally {
        isLoading.hide()
      }
    },
    async getSurgeryProceduresProducts () {
      try {
        const res = await this.api.getSurgeryProceduresProducts(this.surgeryId)
        this.procedures = []
        res.data.map(el => {
          const procedure = {
            id: el.clinic_procedure.id,
            name: el.clinic_procedure.name,
            code: el.clinic_procedure.code,
            type: el.clinic_procedure.type,
            items: [],
          }
          if (el?.clinic_procedure?.procedure_kit_template) {
            el.clinic_procedure.procedure_kit_template.map(kit => {
              procedure.items.push({
                id: kit?.kit_template?.id,
                name: kit?.kit_template?.name,
                quantity: kit.quantity,
                code: kit?.kit_template?.code,
                products: kit.kit_template.kit_template_products.map(product => {
                  return {
                    name: product.product.name,
                    quantity: product.quantity,
                    code: product.product.barcode,
                  }
                })
              })
            })
          }

          if (el?.clinic_procedure?.procedure_product) {
            el.clinic_procedure.procedure_product.map(product => {
              procedure.items.push({
                id: product?.product?.id,
                name: product.product.name,
                quantity: product.quantity,
                code: product.product.barcode,
              })
            })
          }

          this.procedures.push(procedure)
        })

      } catch (error) {
        this.$toast.error('Erro ao carregar os produtos da cirurgia, contate o suporte se o erro persistir')
      }
    },
    async getSurgeryItems () {
      try {
        this.loadingItems = true
        const res = await this.api.getSurgeryItems(this.surgeryId)
        this.surgeryItems = res.data
        this.loadingItems = false
      } catch (error) {
        this.$toast.error('Erro ao carregar os itens da cirurgia, contate o suporte se o erro persistir')
      }
    },
    async updateSurgeryItemUsage (id, newSituation) {
      try {
        const item = this.surgeryItems.find(el => el.id === id)
        this.$set(item, 'situation', newSituation)
        await this.api.updateSurgeryItem(id, { situation: newSituation })
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    async removeSurgeryItem (id) {
      try {
        this.surgeryItems = this.surgeryItems.filter(item => item.id !== id)
        await this.api.deleteSurgeryItem(id)
      } catch (err) {
        this.$toast.error(err.message)
      }
    }
  }

}
</script>

<style lang="scss">
#surgery-products-usage {
  height: 100%;
  padding: 30px 24px;

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 16px;

    .icon {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>